import createSvgIcon from '../Utils/CreateSvgIcon';

export const GooglepayCheckout = createSvgIcon(
  <svg width="42" height="24" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.25"
      y="0.75"
      width="38.5"
      height="24.5"
      rx="3.75"
      stroke="#EFEFEF"
      stroke-width="1.5"
    />
    <path
      d="M14.6211 12.2721C14.6211 11.9595 14.5935 11.6603 14.5424 11.3727H10.585V13.0208L12.8641 13.0213C12.7717 13.5696 12.4742 14.0371 12.0183 14.3487V15.418H13.375C14.1672 14.6733 14.6211 13.5726 14.6211 12.2721Z"
      fill="#4285F4"
    />
    <path
      d="M12.0193 14.3487C11.6417 14.6074 11.1554 14.7587 10.5864 14.7587C9.48743 14.7587 8.55512 14.0066 8.22123 12.9928H6.82178V14.0955C7.51511 15.4929 8.94014 16.4518 10.5864 16.4518C11.7243 16.4518 12.6802 16.0717 13.376 15.4175L12.0193 14.3487Z"
      fill="#34A853"
    />
    <path
      d="M8.08819 12.1748C8.08819 11.8901 8.13491 11.615 8.21998 11.3563V10.2535H6.82052C6.53385 10.8314 6.37256 11.4836 6.37256 12.1748C6.37256 12.866 6.53434 13.5182 6.82052 14.0961L8.21998 12.9933C8.13287 12.7295 8.08837 12.4531 8.08819 12.1748Z"
      fill="#FABB05"
    />
    <path
      d="M10.5864 9.59024C11.2075 9.59024 11.7636 9.80749 12.2027 10.232L13.405 9.01192C12.6748 8.32122 11.7228 7.89722 10.5864 7.89722C8.94063 7.89722 7.51511 8.8561 6.82178 10.2535L8.22123 11.3562C8.55512 10.3424 9.48743 9.59024 10.5864 9.59024Z"
      fill="#E94235"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.6547 18.0749V17.1016C29.7436 17.1238 29.9437 17.1238 30.0439 17.1238C30.6 17.1238 30.9004 16.8903 31.0839 16.2897C31.0839 16.2786 31.1896 15.9338 31.1896 15.9283L29.0763 10.0719H30.3776L31.8572 14.8327H31.8792L33.3587 10.0719H34.6267L32.4353 16.2285C31.935 17.6467 31.3566 18.1028 30.1442 18.1028C30.0441 18.1028 29.7434 18.0915 29.6547 18.0749ZM20.629 7.89728C22.1861 7.89728 23.2706 8.97066 23.2706 10.5335C23.2706 12.1017 22.1639 13.1807 20.59 13.1807H18.8659V15.9226H17.6201V7.89728H20.629ZM18.8659 12.1351H20.2952C21.3796 12.1351 21.9971 11.5513 21.9971 10.539C21.9971 9.52685 21.3798 8.9486 20.3009 8.9486H18.866L18.8659 12.1351ZM23.596 14.2597C23.596 13.2363 24.3801 12.6079 25.7707 12.5299L27.3722 12.4354V11.9849C27.3722 11.3342 26.9327 10.9449 26.1989 10.9449C25.5035 10.9449 25.0697 11.2786 24.9641 11.8013H23.8296C23.8964 10.7446 24.7972 9.96601 26.2433 9.96601C27.6613 9.96601 28.5679 10.7168 28.5679 11.8903V15.9224H27.4166V14.9602H27.3888C27.0498 15.6109 26.3099 16.0225 25.5425 16.0225C24.3968 16.0225 23.596 15.3107 23.596 14.2597ZM27.3722 13.7312V13.2697L25.9318 13.3587C25.2143 13.4087 24.8085 13.7257 24.8085 14.2262C24.8085 14.7378 25.2311 15.0715 25.8763 15.0715C26.7159 15.0715 27.3722 14.4931 27.3722 13.7312Z"
      fill="black"
    />
  </svg>,
  'GooglepayCheckout',
);
