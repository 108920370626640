import createSvgIcon from '../Utils/CreateSvgIcon';

export const AmexCheckout = createSvgIcon(
  <svg width="42" height="24" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.75"
      y="0.75"
      width="38.5"
      height="24.5"
      rx="3.75"
      stroke="#EFEFEF"
      stroke-width="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.38574 9.53845L5 16.4526H9.05321L9.55569 15.3502H10.7042L11.2067 16.4526H15.6682V15.6112L16.0657 16.4526H18.3735L18.7711 15.5935V16.4526H28.0497L29.1779 15.3789L30.2343 16.4526L35 16.4615L31.6036 13.0148L35 9.53845H30.3082L29.21 10.5924L28.1868 9.53845H18.0929L17.2261 11.3231L16.339 9.53845H12.2943V10.3512L11.8443 9.53845H8.38574ZM9.16988 10.521H11.1456L13.3914 15.2096V10.521H15.5557L17.2903 13.8827L18.8889 10.521H21.0424V15.4824H19.7321L19.7214 11.5947L17.811 15.4824H16.6388L14.7177 11.5947V15.4824H12.022L11.5109 14.3701H8.7499L8.2399 15.4814H6.79556L9.16988 10.521ZM27.5694 10.521H22.2412V15.4795H27.4869L29.1777 13.8362L30.8073 15.4795H32.5109L30.0348 13.0146L32.5109 10.521H30.8812L29.1991 12.1455L27.5694 10.521ZM10.1312 11.3594L9.22157 13.3408H11.0398L10.1312 11.3594ZM23.5573 12.4552V11.5495V11.5487H26.882L28.3326 12.9971L26.8177 14.4535H23.5573V13.4647H26.4641V12.4552H23.5573Z"
      fill="#044BBC"
    />
  </svg>,
  'AmexCheckout',
);
