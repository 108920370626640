import createSvgIcon from '../Utils/CreateSvgIcon';

export const Mastercard = createSvgIcon(
  <svg width="40" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.75"
      y="0.75"
      width="38.5"
      height="24.5"
      rx="3.75"
      stroke="#EFEFEF"
      stroke-width="1.5"
    />
    <ellipse cx="14.6344" cy="13" rx="8.58555" ry="8.57145" fill="#EB001B" />
    <ellipse cx="25.3668" cy="13" rx="8.58555" ry="8.57145" fill="#F79E1B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9999 19.6915C18.0371 18.1207 16.7803 15.7069 16.7803 13C16.7803 10.2932 18.0371 7.87938 19.9999 6.30853C21.9627 7.87938 23.2195 10.2932 23.2195 13C23.2195 15.7069 21.9627 18.1207 19.9999 19.6915Z"
      fill="#FF5F00"
    />
  </svg>,
  'Mastercard',
);
