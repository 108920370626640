import createSvgIcon from '../Utils/CreateSvgIcon';

export const Promotion = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4995 7C15.3279 7 15.9995 6.32843 15.9995 5.5C15.9995 4.67157 15.3279 4 14.4995 4C13.6711 4 12.9995 4.67157 12.9995 5.5C12.9995 6.32843 13.6711 7 14.4995 7Z"
      fill="#303030"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1147 0.886475L10.6093 0.886488C10.0601 0.886488 9.5351 1.11232 9.1574 1.51101L1.14184 9.97204C0.381635 10.7745 0.416534 12.0415 1.21976 12.8008L7.33993 18.587C8.11615 19.3208 9.33206 19.3151 10.1014 18.5741L18.0929 10.8765C18.4531 10.5294 18.6703 10.06 18.7015 9.56078L19.1108 3.01123C19.1828 1.85983 18.2684 0.886471 17.1147 0.886475ZM10.6093 2.88649L17.1147 2.88647L16.7054 9.43602L8.71392 17.1336L2.59376 11.3475L10.6093 2.88649Z"
      fill="#303030"
    />
  </svg>,
  'Promotion',
);
