import createSvgIcon from '../Utils/CreateSvgIcon';

export const Observe = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 41 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3257 23.6259C12.2231 22.3635 7.7883 18.1906 5.02458 14.2499C7.7883 10.3091 12.2231 6.13624 17.3257 4.87384C20.0381 4.20278 22.9832 4.33805 26.0978 5.81918C29.0103 7.20422 32.1706 9.81537 35.4256 14.2499C32.1706 18.6844 29.0103 21.2956 26.0978 22.6806C22.9832 24.1617 20.0381 24.297 17.3257 23.6259ZM39.4284 13.1402C35.6336 7.64657 31.7145 4.1662 27.7748 2.29267C23.802 0.403433 19.9285 0.207198 16.3879 1.08318C9.43896 2.80238 3.95999 8.58339 1.02875 13.204L0.365234 14.2499L1.02875 15.2958C3.95999 19.9164 9.43896 25.6974 16.3879 27.4166C19.9285 28.2926 23.802 28.0964 27.7748 26.2071C31.7145 24.3336 35.6336 20.8532 39.4284 15.3596L40.195 14.2499L39.4284 13.1402ZM24.1547 14.2499C24.1547 16.4065 22.4064 18.1548 20.2497 18.1548C18.0931 18.1548 16.3448 16.4065 16.3448 14.2499C16.3448 12.0932 18.0931 10.3449 20.2497 10.3449C22.4064 10.3449 24.1547 12.0932 24.1547 14.2499ZM28.0596 14.2499C28.0596 18.5632 24.563 22.0598 20.2497 22.0598C15.9364 22.0598 12.4398 18.5632 12.4398 14.2499C12.4398 9.9366 15.9364 6.44 20.2497 6.44C24.563 6.44 28.0596 9.9366 28.0596 14.2499Z"
      fill="white"
    />
  </svg>,
  'Observe',
);
