import React from 'react';
import createSvgIcon from '../Utils/CreateSvgIcon';

export const Compare = createSvgIcon(
  <React.Fragment>
    <mask
      id="mask0_8510_35789"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="3"
      width="21"
      height="18"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.07184 11.4335C3.04433 11.5259 3.03036 11.6219 3.03036 11.7184L3.03036 19.9902C3.03036 20.5423 3.47786 20.9898 4.02987 20.9898L7.76138 20.9898C8.3134 20.9898 8.7609 20.5423 8.7609 19.9902L8.7609 17.9912L15.291 17.9912L15.291 19.9902C15.291 20.5423 15.7385 20.9898 16.2906 20.9898L20.0221 20.9898C20.5741 20.9898 21.0216 20.5423 21.0216 19.9902L21.0216 11.7184C21.0216 11.6219 21.0076 11.5259 20.9801 11.4335L20.7555 10.6783L22.002 9.12024L20.441 7.87146L20.0617 8.34561L18.9811 4.71261C18.855 4.2887 18.4653 3.99805 18.023 3.99805L12.026 3.99805L6.02889 3.99805C5.58663 3.99805 5.19695 4.2887 5.07086 4.71261L3.97673 8.39116L3.56097 7.87146L1.99999 9.12024L3.2829 10.7239L3.07184 11.4335ZM17.2901 17.9912L19.0226 17.9912L19.0226 15.9922L5.02938 15.9922L5.02938 11.9941L19.0226 11.9941L19.0226 11.8639L19.0226 18.9907L17.2901 18.9907L17.2901 17.9912ZM5.02938 17.9912L6.76187 17.9912L6.76187 18.9907L5.02938 18.9907L5.02938 17.9912ZM6.77439 5.99707L5.58523 9.99512L18.4667 9.99512L17.2775 5.99707L12.026 5.99707L6.77439 5.99707ZM7.02841 13.9932C7.02841 14.5452 7.4759 14.9927 8.02792 14.9927C8.57994 14.9927 9.02743 14.5452 9.02743 13.9932C9.02743 13.4412 8.57993 12.9937 8.02792 12.9937C7.4759 12.9937 7.02841 13.4412 7.02841 13.9932ZM16.024 14.9927C15.472 14.9927 15.0245 14.5452 15.0245 13.9932C15.0245 13.4412 15.472 12.9937 16.024 12.9937C16.576 12.9937 17.0235 13.4412 17.0235 13.9932C17.0235 14.5452 16.576 14.9927 16.024 14.9927Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_8510_35789)">
      <rect
        x="-0.5"
        y="0.5"
        width="9"
        height="21"
        transform="matrix(-1 0 0 1 23.002 2)"
        fill="white"
        stroke="white"
      />
    </g>
    <mask
      id="mask1_8510_35789"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="3"
      width="21"
      height="18"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9301 11.4335C20.9576 11.5259 20.9716 11.6219 20.9716 11.7184L20.9716 19.9902C20.9716 20.5423 20.5241 20.9898 19.9721 20.9898L16.2406 20.9898C15.6886 20.9898 15.2411 20.5423 15.2411 19.9902L15.2411 17.9912L8.71091 17.9912L8.71091 19.9902C8.71091 20.5423 8.26341 20.9898 7.7114 20.9898L3.97989 20.9898C3.42787 20.9898 2.98038 20.5423 2.98038 19.9902L2.98038 11.7184C2.98038 11.6219 2.99435 11.5259 3.02186 11.4335L3.24646 10.6783L2 9.12024L3.56097 7.87146L3.94029 8.34561L5.02088 4.71261C5.14697 4.2887 5.53665 3.99805 5.97891 3.99805L11.976 3.99805L17.9731 3.99805C18.4153 3.99805 18.805 4.2887 18.9311 4.71261L20.0252 8.39116L20.441 7.87146L22.002 9.12024L20.7191 10.7239L20.9301 11.4335ZM6.71189 17.9912L4.9794 17.9912L4.9794 15.9922L18.9726 15.9922L18.9726 11.9941L4.9794 11.9941L4.9794 11.8639L4.9794 18.9907L6.71189 18.9907L6.71189 17.9912ZM18.9726 17.9912L17.2401 17.9912L17.2401 18.9907L18.9726 18.9907L18.9726 17.9912ZM17.2276 5.99707L18.4167 9.99512L5.53525 9.99512L6.72441 5.99707L11.976 5.99707L17.2276 5.99707ZM16.9735 13.9932C16.9735 14.5452 16.526 14.9927 15.974 14.9927C15.422 14.9927 14.9745 14.5452 14.9745 13.9932C14.9745 13.4412 15.422 12.9937 15.974 12.9937C16.526 12.9937 16.9735 13.4412 16.9735 13.9932ZM7.97794 14.9927C8.52995 14.9927 8.97745 14.5452 8.97745 13.9932C8.97745 13.4412 8.52995 12.9937 7.97794 12.9937C7.42592 12.9937 6.97843 13.4412 6.97843 13.9932C6.97843 14.5452 7.42592 14.9927 7.97794 14.9927Z"
        fill="#303030"
      />
    </mask>
    <g mask="url(#mask1_8510_35789)">
      <rect x="0.5" y="2.5" width="9" height="21" fill="white" stroke="white" />
    </g>
    <rect x="11.002" y="2" width="2" height="20" fill="white" />
  </React.Fragment>,
  'Compare',
);
